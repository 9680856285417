import React, { useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import ButtonHowItWorks from 'components/ui/ButtonHowItWorks';
import HowItWorksVideoModal from 'components/modals/HowItWorksVideoModal';
import { routesByName } from 'constants/routes';
import { toggleHowItWorksVideoModalAction } from 'modules/layout/store/actions';
import infoToastr from 'libs/toastr/infoToastr';
import { useSectionRefs } from 'hooks/useSectionRefs';
import ptlFaqImage from 'assets/img/faqs-page/ptl-faqs-img.png';
import faq1Img from 'assets/img/faqs-page/faq-1.png';
import signUpModal from 'assets/img/faqs-page/sign-up-modal.png';
import preferencesModal from 'assets/img/faqs-page/preferences-modal.png';
import registerNowBtn from 'assets/img/faqs-page/register-now-btn.png';
import howDoesPinWork1 from 'assets/img/faqs-page/howDoesPinWorkImg1.png';
import howDoesPinWork2 from 'assets/img/faqs-page/howDoesPinWorkImg2.png';
import whereIsProfileInfoImg from 'assets/img/faqs-page/whereIsProfileInfoImg.jpg';
import howCanClaimImg from 'assets/img/faqs-page/howCanClaimImg.jpg';
import howAreImages from 'assets/img/faqs-page/howAreImages.png';
import faqtakeStyleQuiz from 'assets/img/faqs-page/faqtakeStyleQuiz.png';
import faqWhatCuratedLooks from 'assets/img/faqs-page/faqWhatCuratedLooks.png';
import faqWhatMMTool from 'assets/img/faqs-page/faqWhatMMTool.png';
import faqWhoAmbassadors from 'assets/img/faqs-page/faqWhoAmbassadors.png';
import sampleShowcaseImg from 'assets/img/sample-showcase-page.png';
import requestSectionImg from 'assets/img/home-page/how-it-works/request.png';
import curateSectionImg from 'assets/img/home-page/how-it-works/curate.png';
import videoCTL from 'assets/video/curateTheLook.mp4';
import videoRTL from 'assets/video/requestTheLook.mp4';
import posterCTL from 'assets/video/curateTheLookPreview.png';
import posterRTL from 'assets/video/requestTheLookPreview.png';
import AccordionItem from './AccordionItem';
import { faqContribute, faqDashboard, sectionTitles } from './config';
import classes from './AmbassadorFaqs.module.scss';

const AmbassadorFaqs = () => {
  const sectionRefs = useSectionRefs(Object.keys(sectionTitles));

  const currentUser = useSelector((state) => state.auth.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [video, setVideo] = useState(videoCTL);
  const [posterImg, setPosterImg] = useState(posterCTL);

  const handleSetVideo = (videoData, posterData) => {
    setVideo(videoData);
    setPosterImg(posterData);
    dispatch(toggleHowItWorksVideoModalAction(true));
  };

  const handleOpenRegisterModal = () => {
    if (!currentUser) {
      history.push(`?${routesByName.auth.key}=${routesByName.auth.signUp}`);
    } else {
      infoToastr('Info', 'You are already logged in.');
    }
  };

  const scrollToSection = (id) => {
    const element = sectionRefs[id].current;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.topSection}>
        <div>
          <h1 className={classes.topSection__title}>PIN THE LOOK FAQs</h1>
          <p className={classes.topSection__description}>
            Pin the Look is a social curation shopping platform that streamlines
            the process of elevating your home. With easy access to popular
            curated looks and personalized ideas from our Home Stylist
            Ambassadors, you can quickly find and compare items to get the look
            you love for your home.
          </p>
          <ul className="mt-4">
            {Object.keys(sectionTitles).map((sectionId) => (
              <li
                className={classes.sectionTitle}
                onClick={() => scrollToSection(sectionId)}
              >
                {sectionTitles[sectionId]}
              </li>
            ))}
          </ul>
        </div>
        <img src={ptlFaqImage} alt="ptlFaqImage" />
      </div>
      <div className={classes.mainSection}>
        <div
          className={classes.accordionSection}
          ref={sectionRefs.aboutPinTheLook}
        >
          <h2 className={classes.accordionSection__title}>
            ABOUT PIN THE LOOK
          </h2>
          <AccordionItem question="WHAT IS PIN THE LOOK?">
            Pin the Look is a social curation platform that streamlines home
            decorating, from inspiration to procurement. With easy access to
            popular curated looks and personalized ideas from our Home Stylist
            Ambassadors, you can quickly find and compare items to get the look
            you love for your home.
            <p className="mt-2">
              Pin the Look offers three primary ways to get curated looks:
            </p>
            <img src={faq1Img} alt="faq1Img" />
            <ol>
              <li>
                <a href={routesByName.getTheLook.index} className="fw-600">
                  Get the Look:{' '}
                </a>
                Quickly view Curated Looks in our main gallery and in Ambassador
                Showcase pages.
              </li>
              <li>
                <a href={routesByName.requestTheLook.index} className="fw-600">
                  Request the Look:{' '}
                </a>
                Request the Look of your favorite images and get matched with
                Home Stylist Ambassadors who provide decor ideas in the form of
                Look Boards.
              </li>
              <li>
                <a href={routesByName.curateTheLook.canvas} className="fw-600">
                  Curate the Look:{' '}
                </a>
                Curate the Look yourself by pinning an image and dragging and
                dropping items from our vast database of furnishings into a Look
                Board template.
              </li>
            </ol>
          </AccordionItem>
          <AccordionItem question="WHAT ARE THE BENEFITS OF USING PIN THE LOOK?">
            There are limitless benefits to being part of the Pin the Look
            community. Whether decorating a new home or redecorating an existing
            residence, Pin the Look offers a better, faster, more social way to
            elevate your home. The benefits of using Pin the Look include:
            <ul>
              <li>Fast access to stylish curated looks for your home</li>
              <li>
                Significant reduction in the time spent searching for items
                online
              </li>
              <li>
                Personalized shopping assistance from our Home Stylist
                Ambassadors
              </li>
              <li>
                Free access to curation tools to create Look Boards with vetted
                products
              </li>
            </ul>
          </AccordionItem>
          <AccordionItem question="HOW DOES PIN THE LOOK WORK?">
            <p>
              You can quickly discover how Pin the Look works by watching our
              &nbsp;<span className="font-italic">How it Works</span>&nbsp;
              video series.
            </p>
            <div className="text-center">
              <button className={classes.howItWorksBtn} type="button">
                PIN THE LOOK:&nbsp;
                <span>HOW IT WORKS</span>
                <SpriteIcon name="play-arrow" />
              </button>
              <div className={classes.howItWorksBtns}>
                <ButtonHowItWorks
                  text="Get The Look"
                  onClick={() => handleSetVideo(videoRTL, posterRTL)}
                />
                <ButtonHowItWorks
                  text="Request The Look"
                  onClick={() => handleSetVideo(videoRTL, posterRTL)}
                />
                <ButtonHowItWorks
                  text="Curate The Look"
                  onClick={() => handleSetVideo(videoCTL, posterCTL)}
                />
              </div>
            </div>
            The primary features of Pin the Look work as follows:
            <ol>
              <li>
                <span className="fw-600">Get the Look: </span>
                In the
                <a
                  href={routesByName.getTheLook.index}
                  className={classes.textLink}
                >
                  Get the Look
                </a>
                section, you can view the world’s most beautiful room images and
                curated looks. There are three ways to view curated looks:
                <ul>
                  <li>
                    <span className="fw-600">Curated Looks: </span>
                    In the
                    <a
                      href={routesByName.curateTheLook.canvas}
                      className={classes.textLink}
                    >
                      Curated Looks
                    </a>
                    section, you can browse options or use the filters to view
                    images and Look Boards by style, room type, and color. If
                    you’re not sure of which style is right for you,
                    <a
                      href={routesByName.styleQuiz}
                      className={classes.textLink}
                    >
                      Take the Style Quiz
                    </a>
                    to get your style profile and curated looks.
                  </li>
                  <li>
                    <span className="fw-600">Mix & Match Looks: </span>
                    Use the
                    <a
                      href={routesByName.getTheLook.mixMatch.index}
                      className={classes.textLink}
                    >
                      Mix & Match
                    </a>
                    tool to scroll through different ways to get the look of an
                    inspiration image you like. You can sort options by “Best
                    Match“ and “Price“.
                  </li>
                  <li>
                    <span className="fw-600">Showcase Looks: </span>
                    View
                    <a
                      href={routesByName.getTheLook.ambassadors.index}
                      className={classes.textLink}
                    >
                      Ambassador Showcases
                    </a>
                    to find truly unique inspiration images and Look Boards
                    curated by skilled Home Stylists.
                  </li>
                </ul>
              </li>
              <li>
                <span className="fw-600">Request the Look: </span>
                You can
                <a
                  href={routesByName.requestTheLook.index}
                  className={classes.textLink}
                >
                  Request the Look
                </a>
                of your favorite room images to get ideas from Ambassadors
                specializing in your style. Simply pin or upload an image,
                select the item types you’re interested in, and submit your
                request. Your Request will be matched with Home Stylist
                Ambassadors, who will bring those images to life.
              </li>
              <li>
                <span className="fw-600">Curate the Look: </span>
                You can also curate the look of images on your own. To
                <a
                  href={routesByName.curateTheLook.canvas}
                  className={clsx(classes.textLink, classes.withComa)}
                >
                  Curate the Look
                </a>
                , you can pin or upload an image or select one from our
                Inspiration Image database. Once you’ve selected an image, you
                can create a Look Board by dragging and dropping items from our
                database, filled with thousands of items, into the template. You
                can curate a Look Board with items you pin online.
              </li>
              <li>
                <span className="fw-600">The Pin Extension: </span>
                To make the most of Pin the Look, add our Chrome Pin extension
                to easily capture your favorite online images. On the
                <a href={routesByName.getThePin} className={classes.textLink}>
                  Get the Pin
                </a>
                page, select the “Get the Chrome Extension” button, then click
                on the “Add to Chrome” button, and it will be added to your
                browser.
              </li>
              <li>
                <span className="fw-600">Your Dashboard: </span>
                Your Dashboard is where you’ll find your saved (or liked) Inspo
                images, Look Boards, Requests, Followers, and Messages.
              </li>
            </ol>
          </AccordionItem>
          <AccordionItem
            question="HOW CAN I CONTRIBUTE TO PIN THE LOOK?"
            className={classes.howContributeBlock}
          >
            Pin the Look is a social curation platform with beautiful
            inspiration images and curated looks contributed by our members.
            Here are ways to add your mark on Pin the Look:
            {faqContribute.map(({ title, text, img }) => (
              <div className="d-flex align-items-center">
                <img src={img} alt="title" />
                <p className="w-65">
                  <span className="fw-600">{title}</span>
                  {text}
                </p>
              </div>
            ))}
          </AccordionItem>
          <AccordionItem question="IS PIN THE LOOK AN INTERIOR DESIGN ASSISTANCE SITE?">
            <p className="mb-2">
              Pin the Look is a platform for people decorating or remodeling
              their homes to easily get the look of their favorite inspiration
              images. Our platform facilitates a social shopping experience that
              saves time and inspires design creativity but does not directly
              serve as an interior design assistant.
            </p>
            <p>
              On Pin the Look, you have access to a multitude of pre-curated
              looks for popular room images, as well as curation tools. By
              Requesting the Look, you will be matched with Ambassadors
              specializing in your style who can curate Look Board ideas and
              offer feedback, saving you time and making the search for products
              more collaborative and fun.
            </p>
          </AccordionItem>
          <AccordionItem question="IS PIN THE LOOK AN E-COMMERCE SITE?">
            Pin the Look is a home decor inspiration platform that makes it easy
            to find items for residential design projects. Look Boards are
            curated with products found anywhere online that represent the best
            ways to get the look of an image. Look Boards may also include items
            from our database of products pinned from partner sites. All
            products included in Look Boards are linked to the original source
            page to make purchasing clean and simple.
          </AccordionItem>
          <AccordionItem question="HOW IS PIN THE LOOK DIFFERENT FROM PINTEREST?">
            Like many platforms on the Web, both Pin the Look and Pinterest have
            proprietary Pin extensions and inspiration images. However,
            Pinterest is a visual discovery engine for finding images of all
            kinds, while Pin the Look is a social curation platform designed to
            help shoppers quickly find home furnishings to get the look of their
            favorite inspo images.
          </AccordionItem>
          <AccordionItem question="IS PIN THE LOOK FREE?">
            Yes, Pin the Look is a free resource to simplify the process of
            getting the look you love for your home. All you need to bring is
            your curiosity and creativity to enjoy a better way to shop for home
            furnishings online.
          </AccordionItem>
        </div>
        <div
          className={classes.accordionSection}
          ref={sectionRefs.registration}
        >
          <h2 className={classes.accordionSection__title}>REGISTRATION</h2>
          <AccordionItem question="HOW DO I REGISTER FOR AN ACCOUNT?">
            <p>
              To register on Pin the Look, click on the
              <span
                className={classes.textLink}
                onClick={handleOpenRegisterModal}
              >
                Register
              </span>
              link in the header and complete your Registration with these
              details:
            </p>
            <ul>
              <li>Personal or Business account</li>
              <li>
                First Name, Last Name, Email Address, Zip Code, and Country
              </li>
              <li>Password</li>
              <li>
                <a
                  href={routesByName.terms}
                  className={clsx(classes.textLink, 'p-0')}
                >
                  Terms Agreement
                </a>
              </li>
            </ul>
            <p className="fw-700">
              <span className="primary-color">*IMPORTANT: </span>
              Once you register, check your email to VERIFY your account
            </p>
            <img src={signUpModal} alt="signUpModal" />
          </AccordionItem>
          <AccordionItem
            question="IS REGISTRATION REQUIRED ON PIN THE LOOK?"
            className={classes.regRequiredBlock}
          >
            <p>
              Registration is required on Pin the Look. While anyone can access
              the homepage, take the Style Quiz, and watch our&nbsp;
              <span className="font-italic">How it Works</span>&nbsp; videos,
              the platform features are reserved for registered users.
            </p>
            <p className="mb-1 mt-1">Registered users can:</p>
            <ul>
              <li>Browse a multitude of popular curated looks</li>
              <li>
                View Ambassador Showcase pages featuring unique images and
                curated looks
              </li>
              <li>Compare ways to get the look using our Mix & Match tool</li>
              <li>Pin images to Curate or Request the Look</li>
              <li>
                Curate the look of images with access to our database of
                thousands of vetted home furnishing items
              </li>
              <li>
                Request the Look of your favorite images to get ideas from our
                Home Stylist Ambassadors
              </li>
            </ul>
            <img
              src={registerNowBtn}
              onClick={handleOpenRegisterModal}
              className="cursor-pointer"
              alt="registerNowBtn"
            />
          </AccordionItem>
          <AccordionItem question="WHY SHOULD I SET MY PREFERENCES?">
            It is important to set your preferences on Pin the Look to optimize
            your experience. By setting your style, color, and room type
            preferences, your default view in the Get the Look section will
            display inspo images and curated looks in your favorite styles.
            <p className="mt-2">
              To update your Preferences, hover over your Profile link in the
              header, select Preferences, and then click the Edit Icon.
            </p>
            <img src={preferencesModal} alt="registerNowBtn" />
          </AccordionItem>
        </div>
        <div
          className={classes.accordionSection}
          ref={sectionRefs.profileDashboard}
        >
          <h2 className={classes.accordionSection__title}>
            PROFILE & DASHBOARD
          </h2>
          <AccordionItem
            question="WHERE IS MY PROFILE INFORMATION?"
            className={classes.whereIsProfileBlock}
          >
            After registering, click on your name in the header to find your
            profile information. In this section, you can:
            <img src={whereIsProfileInfoImg} alt="whereIsProfileInfoImg" />
            In this section, you can:
            <ul>
              <li>Change your Name</li>
              <li>Add an optional Display Name</li>
              <li>Upload a profile image</li>
              <li>Add a Description</li>
              <li>Update your Email</li>
              <li>Update your Password</li>
              <li>Deactivate your Account</li>
            </ul>
          </AccordionItem>
          <AccordionItem question="DASHBOARD">
            <p>
              Your Pin the Look
              <a
                href={routesByName.dashboard.index}
                className={classes.textLink}
              >
                Dashboard
              </a>
              stores your approved Inspiration Images, Look Boards, and
              Requests, as well as your Followers and messages. The five
              Dashboard sections are:
            </p>
            {faqDashboard.map(({ title, text, img }) => (
              <div className="d-flex align-items-center mt-2">
                <div className={classes.faqIconWrapper}>
                  <SpriteIcon name={img} />
                </div>
                <p>
                  <span className="fw-600">{title}</span>
                  {text}
                </p>
              </div>
            ))}
          </AccordionItem>
        </div>
        <div
          className={classes.accordionSection}
          ref={sectionRefs.pinningImages}
        >
          <h2 className={classes.accordionSection__title}>PINNING IMAGES</h2>
          <AccordionItem question="WHAT IS THE PIN?">
            <p>
              The
              <a href={routesByName.getThePin} className={classes.textLink}>
                Pin the Look Pin
              </a>
              is a proprietary Chrome browser extension that lets you easily
              capture your favorite images online to request or curate the look.
            </p>
          </AccordionItem>
          <AccordionItem
            question="HOW DOES THE PIN WORK?"
            className={classes.howDoesPinBlock}
          >
            Once you have added our Pin Extension, you can start pinning
            inspiration images and e-commerce products in Chrome. You will then
            see the Pin icon on many larger images online and can always access
            the Pin from your extensions tab in your Chrome browser.
            <div className="d-flex justify-content-between mt-2 mb-2">
              <img src={howDoesPinWork1} alt="howDoesPinWork1" />
              <img src={howDoesPinWork2} alt="howDoesPinWork2" />
            </div>
            Here’s how it works:
            <ol>
              <li>
                <span className="fw-600">Open Pin Extension: </span>
                When you see an Inspo image or e-commerce product online that
                you’d like to Pin, simply open your Chrome browser Extensions
                list and click on the Pin the Look Pin Extension.
              </li>
              <li>
                <span className="fw-600">Select Image: </span>
                When you click on the Pin Extension in your browser, a Pin the
                Look pop-up will appear that displays all of the larger images
                from the page you are on. Select the image you want to pin and
                press “Next.”
              </li>
              <li>
                <span className="fw-600">Open Image in Pin the Look: </span>
                Select the section on Pin the Look where you would like to open
                the image:
                <ul>
                  <li>Request the Look</li>
                  <li>Curate the Look</li>
                  <li>Dashboard</li>
                </ul>
              </li>
              <li>
                <span className="fw-600">Request, Curate, or Save: </span>
                You can then Request the Look of the image, Curate the Look with
                the image, or save the image to your Dashboard.
              </li>
            </ol>
          </AccordionItem>
          <AccordionItem question="DOES THE PIN WORK IN ALL BROWSERS?">
            <p>
              At this time, the{' '}
              <span className="fw-600 font-italic">
                Pin only works in Chrome
              </span>
              . We recommend using Chrome to search for inspiration images.
              Additional browser extensions may be added in the future.
            </p>
          </AccordionItem>
        </div>
        <div
          className={classes.accordionSection}
          ref={sectionRefs.inspirationImages}
        >
          <h2 className={classes.accordionSection__title}>
            INSPIRATION IMAGES
          </h2>
          <AccordionItem question="WHAT ARE THE INSPIRATION IMAGE SOURCES?">
            Inspiration Images on Pin the Look are pinned online by registered
            users, uploaded by image owners, or licensed by Pin the Look.
          </AccordionItem>
          <AccordionItem question="WHAT IS THE SELECTION CRITERIA FOR IMAGES DISPLAYED ON PIN THE LOOK?">
            Pin the Look is on a quest to find the world’s most beautiful room
            images. We invite you to add your own images and pin your favorites
            from the Web for consideration. We will only approve and display
            images that meet our quality standards, add value to the selection,
            and abide by copyright guidelines.
          </AccordionItem>
          <AccordionItem question="CAN IMAGES BE UPLOADED FROM A CAMERA ROLL?">
            On Pin the Look, you can upload camera roll images from your
            computer, laptop, or tablet to Request or Curate the Look.
            Currently, images cannot be uploaded directly from your mobile
            phone.
          </AccordionItem>
          <AccordionItem
            question="HOW ARE MY IMAGES USED ON PIN THE LOOK?"
            className={classes.howAreImagesBlock}
          >
            If you add an Inspiration Image that you own the rights to, you can
            specify if it is “Open” or “Exclusive.”
            <img src={howAreImages} alt="howAreImages" />
            <p className="mb-2">
              <span className="fw-600">Open: </span>
              Images marked “Open” may be displayed in our Inspiration Image
              database for others to Request and Curate the Look. These images
              will not automatically appear in our databases; they are subject
              to approval by Pin the Look.
            </p>
            <p>
              <span className="fw-600">Exclusive: </span>
              If you upload an image that you own and mark it “Exclusive,” only
              you can curate or request the look of the image, and it will not
              appear in our Inspiration Image databases.
            </p>
          </AccordionItem>
          <AccordionItem
            question="WHERE CAN I ACCESS MY ADDED IMAGES?"
            className={classes.whereICanAccessBlock}
          >
            All “approved” added images are stored in your Dashboard, which you
            can access from the header once registered.
            <Button
              size="sm"
              className={classes.whereICanAccessBtn}
              onClick={() => {
                if (currentUser) {
                  history.push(routesByName.dashboard.index);
                }
              }}
            >
              <SpriteIcon name="dashboard" size="sm" className="mr-1" />
              Dashboard
            </Button>
          </AccordionItem>
          <AccordionItem question="HOW LONG ARE IMAGES STORED?">
            <p>
              Images that are “Approved” will be stored by Pin the Look
              indefinitely. Any images added that are not used to&nbsp;
              <span className="fw-700">Curate the Look</span>
              &nbsp;or&nbsp;
              <span className="fw-700">Request the Look</span>, and remain
              “Unapproved” will be deleted from the platform after 60 days.
            </p>
          </AccordionItem>
        </div>
        <div className={classes.accordionSection} ref={sectionRefs.styleQuiz}>
          <h2 className={classes.accordionSection__title}>STYLE QUIZ</h2>
          <AccordionItem
            question="WHAT IS THE STYLE QUIZ?"
            className={classes.whereICanAccessBlock}
          >
            <p>
              Pin the Look`s
              <a href={routesByName.styleQuiz} className={classes.textLink}>
                Style Quiz
              </a>
              helps identify your favorite interior design style and provides
              curated looks based on your answers. Simply select your favorite
              style vibe, room images, room types, and color palettes to get an
              overview of your interior design profile. Once you review your
              profile, you can view curated look boards in the styles you love.
            </p>
            <p className="mt-2">
              Taking the Style Quiz is a fun way to learn more about interior
              design styles and what they say about you!
            </p>
            <img src={faqtakeStyleQuiz} alt="faqtakeStyleQuiz" />
            <Button
              size="lg"
              className={classes.whereICanAccessBtn}
              onClick={() => {
                history.push(routesByName.styleQuiz);
              }}
            >
              Take the Style Quiz
            </Button>
          </AccordionItem>
          <AccordionItem question="CAN I CHANGE MY STYLE QUIZ ANSWERS?">
            <p>
              The
              <a href={routesByName.styleQuiz} className={classes.textLink}>
                Style Quiz
              </a>
              on Pin the Look can be taken as many times as you`d like! While
              taking the quiz, you can “Go Back“ to change your answers before
              pressing “Submit“. Once you press “Submit“, you must retake the
              Style Quiz to change your answers.
            </p>
          </AccordionItem>
          <AccordionItem question="CAN STYLE QUIZ RESPONSES BE USED AS PREFERENCES?">
            <p>
              After you submit your
              <a href={routesByName.styleQuiz} className={classes.textLink}>
                Style Quiz
              </a>
              responses, you can set (or reset) your Preferences with your Style
              Quiz responses by clicking on the “Save as Preferences” button
              shown at the bottom of your Style Profile page.
            </p>
          </AccordionItem>
        </div>
        <div className={classes.accordionSection} ref={sectionRefs.getTheLook}>
          <h2 className={classes.accordionSection__title}>GET THE LOOK</h2>
          <AccordionItem question="WHAT IS THE BEST WAY TO GET THE LOOK?">
            <p>
              There are multiple ways to Get the Look of images on Pin the Look.
              The easiest way is to go to the
              <a
                href={routesByName.getTheLook.index}
                className={classes.textLink}
              >
                Get the Look
              </a>
              section and use the filters to find curated looks in your style.
              If you’re unsure of your style, you can
              <a href={routesByName.styleQuiz} className={classes.textLink}>
                Take the Style Quiz
              </a>
              to discover your interior profile and get curated looks that are
              right for you. You can also
              <a
                href={routesByName.requestTheLook.index}
                className={classes.textLink}
              >
                Request the Look
              </a>
              of your favorite images to get ideas from our Home Stylist
              Ambassadors.
            </p>
          </AccordionItem>
          <AccordionItem question="WHAT ARE CURATED LOOKS?">
            <p>
              <a
                href={routesByName.getTheLook.index}
                className="primary-color text-underline"
              >
                Curated Looks
              </a>
              &nbsp;are Look Boards that show coordinating items selected to get
              the look of an inspiration image. The Get the Look section
              includes hundreds of curated Look Boards that show different ways
              to get the look of some of the world’s most beautiful inspiration
              images.
            </p>
            <img
              src={faqWhatCuratedLooks}
              className="w-75 mt-2"
              alt="faqWhatCuratedLooks"
            />
          </AccordionItem>
          <AccordionItem question="WHAT IS THE MIX & MATCH TOOL?">
            <p>
              The&nbsp;
              <a
                href={routesByName.getTheLook.mixMatch.index}
                className="primary-color text-underline"
              >
                Mix & Match tool
              </a>
              , found in the Get the Look section, is used to scroll through all
              proposed options to Get the Look of an inspiration image to select
              the ones you like best. You can also sort by price to find the
              best way to get the look in your price range.
            </p>
            <img src={faqWhatMMTool} alt="faqWhatMMTool" />
          </AccordionItem>
          <AccordionItem
            question="WHAT ARE SHOWCASE PAGES?"
            className={classes.sampleShowcaseBlock}
          >
            <p>
              <a
                href={routesByName.getTheLook.ambassadors.index}
                className="primary-color text-underline"
              >
                Showcase Pages
              </a>
              &nbsp;are home decor portfolios created by our Home Stylist
              Ambassadors, decor lovers with a keen eye for design and a passion
              for helping others create beautiful spaces. A Showcase is a
              mini-site within Pin the Look where an Ambassador features their
              favorite inspiration images and Look Board designs. Showcases are
              often theme-based and include unique inspiration images and
              curated looks that cannot be found in the main Curated Looks
              section. Key features on a Showcase page include:
            </p>
            <ul>
              <li>Featured Inspiration Images</li>
              <li>Featured Look Boards</li>
              <li>Ambassador’s Style Story</li>
              <li>Ambassador Video Intro (optional)</li>
              <li>Downloadable Showcase Images to Share on Social Media</li>
              <li>Links to the Ambassador’s Social Media Pages</li>
              <li>Chat Box to Message an Ambassador</li>
            </ul>
            <img src={sampleShowcaseImg} className="" alt="sampleShowcaseImg" />
            <p className="fw-600 text-center">Showcase Example</p>
          </AccordionItem>
          <AccordionItem question="HOW CAN I ACCESS AMBASSADOR SHOWCASE PAGES?">
            <p>
              Ambassador Showcase pages can be accessed from the Get the Look
              section. Simply click the “Showcase Looks” button to go to the
              <a
                href={routesByName.getTheLook.ambassadors.index}
                className={classes.textLink}
              >
                Ambassador Showcase
              </a>
              directory, which displays Showcase profile cards and sample Look
              Boards for each Ambassador. You can search for Ambassadors by
              style or name. To view an Ambassador’s Showcase page, click the
              “Go to Showcase” button on the Showcase Profile card.
            </p>
          </AccordionItem>
        </div>
        <div
          className={classes.accordionSection}
          ref={sectionRefs.requestTheLook}
        >
          <h2 className={classes.accordionSection__title}>REQUEST THE LOOK</h2>
          <AccordionItem question="HOW CAN I REQUEST THE LOOK OF AN IMAGE?">
            <p>
              To
              <a
                href={routesByName.requestTheLook.index}
                className={classes.textLink}
              >
                Request the Look
              </a>
              of an image, go to the Request the Look section and pin or upload
              an image you love. Select up to six items you are interested in
              (item types shown in the room image), and add an optional note
              about what you’re looking for. Your request will be matched with
              Ambassadors specializing in the style of the room image provided.
            </p>
            <p className="mt-2">
              Once your Request is matched, you will soon receive Look Board
              ideas. You will be notified via email when Recommendations are
              available. All recommendations will be consolidated on one
              “Recommendations” page so that you can compare them and Mix &
              Match items from all Look Boards.
            </p>
            <p className="mt-2">
              Please note that we will curate the look of “residential room
              images” only. If items cannot be found for the room image in your
              Request, you will be notified that your Request cannot be curated.
            </p>
            <img src={requestSectionImg} alt="requestSectionImg" />
          </AccordionItem>
          <AccordionItem question="HOW ARE IMAGE REQUESTS CURATED?">
            <p>
              When you Request the Look, your request will be matched with&nbsp;
              <a
                href={routesByName.getTheLook.ambassadors.index}
                className="primary-color text-underline"
              >
                Ambassadors
              </a>
              &nbsp;specializing in the style of your image. When an ambassador
              chooses to curate the look for a request, they will search for the
              best-matching items online and from our database and use our easy
              drag-and-drop Curation tool to create Look Board ideas that will
              be sent to you.
            </p>
          </AccordionItem>
          <AccordionItem question="DOES PIN THE LOOK USE AI TO CURATE REQUESTS?">
            Pin the Look`s images are curated and pinned by real people: our
            talented Ambassadors who are decor enthusiasts, interior designers,
            or decor influencers. Our Ambassadors curate Look Boards for their
            Showcase pages and Requests submitted by our users. You can message
            Ambassadors on their Showcase page to communicate directly with
            them.
          </AccordionItem>
        </div>
        <div
          className={classes.accordionSection}
          ref={sectionRefs.curateTheLook}
        >
          <h2 className={classes.accordionSection__title}>CURATE THE LOOK</h2>
          <AccordionItem question="HOW CAN I CURATE THE LOOK OF AN IMAGE?">
            <p>
              To
              <a
                href={routesByName.curateTheLook.canvas}
                className={clsx(classes.textLink, classes.withComa)}
              >
                Curate the Look
              </a>
              , select an image from our database or one you’ve pinned or
              uploaded. Once you select an image, you can access our database of
              thousands of vetted products. You can use the Filters to search by
              product type, style, and color. Once you find products you like,
              simply drag and drop them into the Look Board template. Then,
              customize the banner title and add a description. Notably, you can
              also curate a Look Board without an inspiration image to see how
              products look together. Your Look Boards will be saved in your
              Dashboard.
            </p>
            <img src={curateSectionImg} alt="curateSectionImg" />
          </AccordionItem>
          <AccordionItem question="CAN I PIN PRODUCTS TO CURATE THE LOOK?">
            <p>
              Products can be pinned from anywhere online to Curate a Look
              Boards. When you pin an e-commerce product online and select the
              “Curate/E-commerce Products” option, that item will show at the
              top of the product database under “My Added Products.” Only you
              will be able to see the products that you personally pin. The
              products visible to all users in our Product database come from
              approved vendors and affiliate partners.
            </p>
            <p className="mt-2">
              If you are a manufacturer, retailer, or artisan,
              <a href={routesByName.contact} className={classes.textLink}>
                Contact Us
              </a>
              to learn more about our partner programs.
            </p>
          </AccordionItem>
          <AccordionItem question="CAN MY LOOK BOARDS BE EDITED?">
            <p>
              To edit a Look Board you created, go to your
              <a
                href={routesByName.dashboard.index}
                className={classes.textLink}
              >
                Dashboard
              </a>
              and click on “My Look Boards,” then hover over the Look Board and
              click on the “Edit” icon located in the lower right corner. This
              will open the Look board in the Curate the Look section, where you
              can change the banner color, title, products, and description.
              Banner color changes will show up immediately, while text or
              product changes will be approved in a timely manner.
            </p>
          </AccordionItem>
        </div>
        <div className={classes.accordionSection} ref={sectionRefs.ambassadors}>
          <h2 className={classes.accordionSection__title}>AMBASSADORS</h2>
          <AccordionItem
            question="WHO ARE AMBASSADORS?"
            className={classes.whoAmbassadorsBlock}
          >
            <p>
              Ambassadors are decor lovers with a keen eye for design and a
              passion for helping others create beautiful spaces.
            </p>
            <img src={faqWhoAmbassadors} alt="faqWhoAmbassadors" />
            <p className="mb-2">
              While some of our Ambassadors have interior design expertise, most
              simply have years of experience decorating their homes, providing
              design tips to friends, and shopping for hidden jewels or great
              finds.
            </p>
            <p>
              We all have friends like this! Their homes are beautifully
              appointed with a tasteful, special quality we love! Pin the Look
              is a platform for these talented home stylists to showcase their
              decorating skills and share their love for home styling with
              others.
            </p>
          </AccordionItem>
          <AccordionItem
            question="WHAT ARE THE BENEFITS OF BEING A PIN THE LOOK AMBASSADOR?"
            className={classes.whereICanAccessBlock}
          >
            If you love decorating and have a keen eye for design, we encourage
            you to apply to join our team of Home Stylist Ambassadors. As an
            Ambassador, you can:
            <ul>
              <li>Join a passionate community of other decor lovers.</li>
              <li>
                Earn reward points for helping others Get the Look. Reward
                points can be redeemed for Gift Cards that can be used to
                purchase home furnishings.
              </li>
              <li>
                Create a showcase page to promote your curated looks and build a
                following online.
              </li>
              <li>
                Join our Affiliate program to earn commissions on sales of
                affiliate items accessed from your Look Boards.
              </li>
            </ul>
            <Button
              size="lg"
              className={classes.whereICanAccessBtn}
              onClick={() => {
                history.push(routesByName.ambassador.index);
              }}
            >
              Become an Ambassador
            </Button>
          </AccordionItem>
          <AccordionItem question="CAN RETAILERS BECOME AMBASSADORS?">
            <p>
              For home furnishing manufacturers and artisans, Pin the Look
              represents a new channel: a way to bring unique items to the
              attention of home decor buyers at the point of high purchase
              intent. Partnership opportunities might include featuring items in
              our product database or getting items promoted on social media by
              active Ambassadors. To learn more,
              <a href={routesByName.contact} className={classes.textLink}>
                Contact Us
              </a>
              and use the Subject line “Partner Inquiries“.
            </p>
          </AccordionItem>
          <AccordionItem
            question="CAN ANYONE BECOME AN AMBASSADOR?"
            className={classes.whereICanAccessBlock}
          >
            <p>
              Pin the Look invites all decor lovers with a keen eye for design
              to apply. The ideal candidates are decor enthusiasts, interior
              designers, and influencers who are not only skilled at decorating
              but also have a passion for helping others create beautiful spaces
              and want to be part of a community focused on home decor curation.
              If this sounds like you, we invite you to apply!
            </p>
            <p className="mt-2">
              Learn more about how to become an Ambassador on the
              <a
                href={routesByName.ambassador.index}
                className={classes.textLink}
              >
                Ambassadors
              </a>
              page.
            </p>
            <Button
              size="lg"
              className={classes.whereICanAccessBtn}
              onClick={() => {
                history.push(routesByName.ambassador.index);
              }}
            >
              Ambassadors
            </Button>
          </AccordionItem>
        </div>
        <div
          className={classes.accordionSection}
          ref={sectionRefs.imageOwnership}
        >
          <h2 className={classes.accordionSection__title}>IMAGE OWNERSHIP</h2>
          <AccordionItem
            question="HOW CAN I CLAIM OWNERSHIP OF AN IMAGE?"
            className={classes.imageOwnershipBlock}
          >
            To claim ownership of an image, click on the three dots at the lower
            right corner of all inspiration images, then select the “Claim
            Image” option.
            <img src={howCanClaimImg} alt="faqClaimOwnership" />
            If you own the image, you can request to:
            <ol>
              <li>Get credit for the image</li>
              <li>Remove the image from the site</li>
            </ol>
            <p>
              Pin the Look will respond to your claim request in a timely manner
              and take the appropriate action.
            </p>
          </AccordionItem>
          <AccordionItem question="HOW CAN I REQUEST THAT AN IMAGE I OWN BE TAKEN OFF THE SITE?">
            If you are an image owner and would like the image removed from Pin
            the Look, click on the three dots located in the lower right corner
            of your image. Select the “I own this Image” option, then click “I
            want this Image Taken Off the Site.” This will take you to the
            “Image Removal Request” form. Please fill in your contact
            information, proof of ownership, and the page URL on Pin the Look
            where your image is shown. Pin the Look will verify your ownership
            information and remove the image promptly.
          </AccordionItem>
          <AccordionItem question="HOW CAN I REPORT INAPPROPRIATE IMAGES OR CONTENT">
            To report inappropriate images or content, click on the three dots
            at the lower right corner of the offensive image, click Claim Image,
            and then select the “This Image is Inappropriate” option. Please
            select from the options listed why you feel the image is
            inappropriate. We will review your report and take any inappropriate
            images down in a timely manner.
          </AccordionItem>
        </div>
      </div>
      <HowItWorksVideoModal video={video} posterImg={posterImg} />
    </div>
  );
};

export default AmbassadorFaqs;
