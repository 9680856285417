import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const LookBoardMeta = ({ lookBoard }) => {
  const rout = useMemo(
    () =>
      `${window.location.origin}/look-board/${lookBoard.id}/${lookBoard.slug}`,
    [lookBoard.id, lookBoard.slug]
  );
  return (
    <Helmet>
      <link rel="canonical" href={rout} />
      <title>{lookBoard.meta_title || lookBoard.title} </title>
      <meta property="og:type" content="website" />
      <meta property="og:name" content={lookBoard.meta_title} />
      <meta
        name="description"
        content={lookBoard.meta_description || lookBoard.title}
      />
      <meta property="og:title" content={lookBoard.meta_title} />
      <meta property="og:description" content={lookBoard.meta_description} />
      <meta property="og:image" content={lookBoard.lookBoardSocialImage} />
      <meta property="og:url" content={window.location.href} />

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "${lookBoard.meta_title}",
            "title": "${lookBoard.meta_title}",
            "description": "${lookBoard.meta_description}",
            "image": "${lookBoard.lookBoardSocialImage}",
            "url": "${window.location.href}",
            "thumbnailUrl": "${lookBoard.lookBoardSocialImage}"
          }
        `}
      </script>
    </Helmet>
  );
};
LookBoardMeta.propTypes = {
  lookBoard: PropTypes.shape({
    meta_title: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    meta_description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    lookBoardSocialImage: PropTypes.string,
  }).isRequired,
};

export default LookBoardMeta;
