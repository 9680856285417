import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import productService from 'modules/product/productService';
import errorToastr from 'libs/toastr/errorToastr';
import { getProductImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import findObjectById from 'utils/findObjectById';
import Button from 'components/ui/Button/Button';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import SimilarGroupSlider from 'modules/lookBoard/SimilarGroupSlider';
import classes from 'modules/lookBoard/LookBoardDetails.module.scss';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import ProductMeta from './ProductMeta';

const ProductDetails = () => {
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const { colors, styles, itemClasses } = useSelector(
    (state) => state.app.enums
  );
  const productImgUrl = getProductImgUrl(
    useSelector((state) => state.app.config)
  );
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const history = useHistory();
  const { pathname, state } = useLocation();
  const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [imgModalOpen, setImgModalOpen] = useState(false);

  const imgUrl = useMemo(() => {
    if (!currentProduct) {
      return null;
    }

    const {
      media: { userId, hash },
    } = currentProduct;

    return buildResourceUrl(productImgUrl.large, userId, hash);
  }, [currentProduct, productImgUrl]);

  const getSimilarProducts = useCallback(async () => {
    const similar = await productService.getSimilarProductById(productId);
    setSimilarProducts(similar);
  }, [productId]);

  useEffect(() => {
    (async () => {
      try {
        const { lookBoardId = null } = state || {};
        const product = lookBoardId
          ? await productService.getProductByLookBoardId(productId, lookBoardId)
          : await productService.getProductById(productId);
        setCurrentProduct(product);
        setLoading(false);
        await getSimilarProducts();
      } catch (e) {
        errorToastr('Error', e.message);
      }
    })();
    // eslint-disable-next-line
  }, [productId]);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleImgModalOpen = useCallback(() => {
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleToggleLikeProduct = useCallback(async () => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    const { id, isLiked } = currentProduct;
    const newLikedStatus = Number(!isLiked);
    await productService.toggleLike(id, newLikedStatus);
    const updatedProduct = {
      ...currentProduct,
      isLiked: newLikedStatus,
    };
    setCurrentProduct(updatedProduct);
  }, [authenticated, currentProduct, history, pathname]);

  useEffect(() => {
    document.getElementById('footer').style.paddingBottom = '100px';

    return () => {
      document.getElementById('footer').style.paddingBottom = '24px';
    };
  }, []);

  return !loading ? (
    <>
      <ProductMeta product={{ ...currentProduct, image: imgUrl }} />
      <div className={classes.root}>
        <div className="d-flex align-items-center mb-3">
          {matchesMediaQuery ? (
            <div className="d-flex align-items-center mt-2" onClick={goBack}>
              <SpriteIcon
                name="left-arrow"
                size="sm"
                className="mr-1 primary-color"
              />
              <span className={classes.smallText}>Back</span>
            </div>
          ) : (
            <>
              <IconButton variant="inverted-white" size="sm" onClick={goBack}>
                <SpriteIcon name="left-arrow" size="sm" />
              </IconButton>
              <span className="primary-color text-sm ml-1">Back</span>
            </>
          )}
        </div>
        <div
          className={clsx({
            'background-white mb-5': !matchesMediaQuery,
          })}
        >
          <div
            className={clsx('d-flex', {
              'flex-column': matchesMediaQuery,
            })}
          >
            <div className={`${classes.lookBoardSection} flex-center`}>
              {!matchesMediaQuery && (
                <IconButton
                  className={classes.productPreviewBtn}
                  variant="inverted-grey"
                  color="primary"
                  size="sm"
                  onClick={handleImgModalOpen}
                >
                  <SpriteIcon name="loupe" size="sm" />
                </IconButton>
              )}
              <img
                src={imgUrl}
                alt={currentProduct.image_alt || `product-${currentProduct.id}`}
                className={clsx({
                  'background-white': matchesMediaQuery,
                })}
                style={{
                  maxWidth: '100%',
                  height: matchesMediaQuery ? 300 : 530,
                  objectFit: 'contain',
                }}
              />
              <IconButton
                className={classes.productLikeBtn}
                variant="inverted-grey"
                color="primary"
                size="sm"
                onClick={handleToggleLikeProduct}
              >
                <SpriteIcon
                  name={currentProduct.isLiked ? 'like-filled' : 'like'}
                  size="sm"
                />
              </IconButton>
            </div>
            <div className={classes.infoSection}>
              {matchesMediaQuery ? (
                <>
                  <div className="d-flex">
                    <h1 className={classes.lookBoardTitle}>
                      {currentProduct.name}
                    </h1>
                    {currentProduct.price ? (
                      currentProduct.price > 0 && (
                        <p
                          className={clsx(
                            'primary-color font-semi-bold',
                            classes.price
                          )}
                        >
                          ${currentProduct.price}
                        </p>
                      )
                    ) : (
                      <p
                        className={clsx(
                          'primary-color font-semi-bold',
                          classes.price
                        )}
                        title="The price is not specified, please go to the Site to clarify it"
                      >
                        N/A
                      </p>
                    )}
                  </div>
                  <p className={`${classes.lookBoardDescription} mb-3`}>
                    {currentProduct.description}
                  </p>
                </>
              ) : (
                <>
                  <h1 className={classes.lookBoardTitle}>
                    {currentProduct.name}
                  </h1>
                  <p className={`${classes.lookBoardDescription} mb-3`}>
                    {currentProduct.description}
                  </p>
                  <div className="d-flex align-items-center mb-3">
                    <div className={classes.priceLine} />
                    {currentProduct.price ? (
                      currentProduct.price > 0 && (
                        <p className="primary-color text-lg font-semi-bold">
                          ${currentProduct.price}
                        </p>
                      )
                    ) : (
                      <p
                        className="primary-color text-lg font-semi-bold"
                        title="The price is not specified, please go to the Site to clarify it"
                      >
                        N/A
                      </p>
                    )}
                  </div>
                </>
              )}

              <div className={`${classes.bordered} text-sm mb-3`}>
                <div className="d-flex mb-2">
                  <span className="font-semi-bold mr-2">Item:</span>
                  <span
                    className={clsx('font-italic', {
                      'color-gray-6': matchesMediaQuery,
                    })}
                  >
                    {
                      findObjectById(currentProduct.itemClassId, itemClasses)
                        ?.singular
                    }
                  </span>
                </div>
                <div className="d-flex mb-2">
                  <span className="font-semi-bold mr-2">Style:</span>
                  <div
                    className={clsx('font-italic', {
                      'color-gray-6': matchesMediaQuery,
                    })}
                  >
                    {currentProduct.styleId.length &&
                      currentProduct.styleId
                        .map((styleId) => {
                          return findObjectById(styleId, styles)?.name;
                        })
                        .join(', ')}
                  </div>
                </div>
                <div className="d-flex">
                  <span className="font-semi-bold mr-2">Color:</span>
                  <div
                    className={clsx('font-italic', {
                      'color-gray-6': matchesMediaQuery,
                    })}
                  >
                    {currentProduct.colorId.length &&
                      currentProduct.colorId
                        .map((colorId) => {
                          return colors[colorId].name;
                        })
                        .join(', ')}
                  </div>
                </div>
              </div>
              {(currentProduct.affiliate_code_url || currentProduct.url) && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={currentProduct.affiliate_code_url ?? currentProduct.url}
                  className={clsx({
                    'd-none': matchesMediaQuery,
                  })}
                >
                  <Button inline>Go to Site</Button>
                </a>
              )}
            </div>
          </div>
        </div>
        {similarProducts.length > 0 && !matchesMediaQuery && (
          <>
            <h2 className={`${classes.lookBoardTitle} mb-3`}>
              View Similar Items
            </h2>

            <SimilarGroupSlider products={similarProducts} />
          </>
        )}
      </div>
      {matchesMediaQuery &&
        (currentProduct.affiliate_code_url || currentProduct.url) && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={currentProduct.affiliate_code_url ?? currentProduct.url}
            className={classes.goToSiteBtn}
          >
            <Button inline>Go to Site</Button>
          </a>
        )}

      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgUrl}
      />
    </>
  ) : (
    <div className="text-center p-5">Loading...</div>
  );
};

export default ProductDetails;
