import React from 'react';
import { compareSlidersConfig } from 'modules/home/constants';
import CompareSliderContent from './CompareSliderContent';

import classes from './CompareSectionSliders.module.scss';

const CompareSectionSliders = () => {
  return (
    <div className={classes.compareSliders}>
      {compareSlidersConfig.map(({ id, title, items }) => (
        <div key={id} className={classes.compareSliderWrapper}>
          <div className={classes.compareSliderTitle}>{title}</div>
          <CompareSliderContent products={items} title={title} />
        </div>
      ))}
    </div>
  );
};

export default CompareSectionSliders;
