import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const ProductMeta = ({ product }) => {
  const rout = useMemo(
    () => `${window.location.origin}/product/${product.id}/${product.slug}`,
    [product.id, product.slug]
  );
  return (
    <Helmet>
      <link rel="canonical" href={rout} />
      <title>{product.meta_title || product.name} </title>
      <meta property="og:type" content="product" />
      <meta property="og:title" content={product.meta_title} />
      <meta
        property="og:description"
        content={product.meta_description || product.name}
      />
      <meta
        name="description"
        content={product.meta_description || product.name}
      />
      <meta property="og:image" content={product.image} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:price:amount" content={product.price} />
      <meta property="og:price:currency" content="USD" />

      <script type="application/ld+json">
        {`
         [{
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "${product.meta_title}",
            "image": [
              "${product.image}"
            ],
            "description": "${product.meta_description}",
            "sku": "${product.id}",
            "offers": {
              "@type": "Offer",
              "url": "${window.location.href}",
              "priceCurrency": "USD",
              "price": "${product.price}",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition"
            }
          },
          {
            "@context": "https://schema.org/",
            "@type": "ImageObject",
            "contentUrl": "${product.image}",
            "creator": {
            "@type": "Organization",
            "name": "pinthelook.com"
             },
             "copyrightNotice": "pinthelook.com",
             "creditText": "pinthelook.com"
          }
         ]
        `}
      </script>
    </Helmet>
  );
};

ProductMeta.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    meta_title: PropTypes.string,
    name: PropTypes.string.isRequired,
    meta_description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};
export default ProductMeta;
