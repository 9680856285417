import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import CreateImageForm from 'modules/pinMedia/CreateImageForm/CreateImageForm';
import createImageValidator from 'modules/pinMedia/CreateImageForm/createImageValidator';
import CreateProductForm from 'modules/pinMedia/CreateProductForm/CreateProductForm';
import mediaTypes from 'constants/mediaTypes';
import createProductValidator from 'modules/pinMedia/CreateProductForm/createProductValidator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reCaptchaActions } from 'constants/V3CaptchaActions';
import classes from '../SaveImageModal/SaveImageModal.module.scss';
import { routesByName } from '../../../constants/routes';
import { imageTypeValuesMap } from '../constants';

const SaveImageModalExtension = ({
  open,
  onClose,
  imgData,
  initialFormValues,
  mediaType,
  onCreateMedia,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const history = useHistory();
  const handleSaveMedia = useCallback(
    async (formValues) => {
      const itemData = JSON.parse(localStorage.getItem('parsedData'));
      localStorage.setItem(
        'parsedData',
        JSON.stringify({ ...itemData, type: formValues.type })
      );
      let reCaptchaToken;
      const priceInCents = itemData.productPrice * 100;
      if (!executeRecaptcha) {
        reCaptchaToken = null;
      }
      reCaptchaToken = await executeRecaptcha(
        mediaType === mediaTypes.product
          ? reCaptchaActions.createProduct
          : reCaptchaActions.createImage
      );
      const type =
        itemData.imageType === mediaTypes.inspirationImage
          ? imageTypeValuesMap.room
          : imageTypeValuesMap.single;
      await onCreateMedia({
        mediaUrl: imgData?.imageUrl,
        formValues: { ...formValues, price: priceInCents, type },
        reCaptchaToken,
      });
      const path = history.location.pathname;
      history.push(routesByName.getTheLook.index);
      history.push(path);
    },
    // eslint-disable-next-line
    [executeRecaptcha, mediaType, onCreateMedia, imgData]
  );

  return (
    <BasicModal open={open} onClose={onClose} maxWidth="md" scroll="body">
      <p className={classes.modalTitle}>
        Add {mediaType === mediaTypes.product ? 'product' : 'image'} information
      </p>
      <div className="flex-fill d-flex">
        <div className={`${classes.imageBlock} mr-3`}>
          <div className={classes.currentImageContainer}>
            <img src={imgData?.imageUrl} alt="imageBlock" />
          </div>
        </div>
        <div className={`${classes.formBlock} pt-2 pr-2`}>
          {mediaType === mediaTypes.product ? (
            <CreateProductForm
              withExtension
              extensionData={imgData}
              initialValues={initialFormValues}
              validate={createProductValidator}
              onSubmit={handleSaveMedia}
            />
          ) : (
            <CreateImageForm
              imageUrl={imgData?.imageUrl}
              imageTitle={imgData?.imageTitle}
              siteSourceUrl={imgData?.url}
              validate={createImageValidator}
              onSubmit={handleSaveMedia}
              mediaType={mediaType}
              pinImage
            />
          )}
        </div>
      </div>
    </BasicModal>
  );
};

SaveImageModalExtension.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mediaType: PropTypes.string.isRequired,
  initialFormValues: PropTypes.shape({}).isRequired,
  imgData: PropTypes.shape({
    imageUrl: PropTypes.string,
    imageTitle: PropTypes.string,
    url: PropTypes.string,
  }),
  onCreateMedia: PropTypes.func.isRequired,
};

SaveImageModalExtension.defaultProps = {
  imgData: {},
};

export default SaveImageModalExtension;
