import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import affiliateProgram from 'assets/img/ambassador-page/affiliate-program.webp';
import affiliatePrograms from 'assets/img/ambassador-page/affiliate-program.png';
import affiliateProducts from 'assets/img/ambassador-page/affiliate-products.png';
import affiliateProductsView from 'assets/img/ambassador-page/affiliate-products-view.png';
import promoteSocials from 'assets/img/ambassador-page/ambassador-steps/promote-socials-2.png';

import classes from './AmbassadorManagement.module.scss';
import SpriteIcon from '../../../components/ui/SpriteIcon';
import Button from '../../../components/ui/Button/Button';
import { routesByName } from '../../../constants/routes';

const AmbassadorProgram = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const handleLinkOpen = (buttonUrl, isExternal, step) => {
    if (isExternal) {
      window.open(buttonUrl, '_blank');
    } else if (step) {
      history.push({
        pathname: buttonUrl,
        search: `?videoUrl=${step}`,
      });
    } else {
      history.push(buttonUrl);
    }
  };
  /*  const handleOpenImageLink = (link, isExternal) => {
    if (isExternal) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  }; */

  return (
    <>
      <section className={classes.root}>
        <div className={classes.banner}>
          <div className="d-flex align-items-center">
            <h2 className="ml-1 text-md text-uppercase font-semi-bold">
              AFFILIATE PROGRAMS
            </h2>
          </div>
        </div>
        <div className={classes.mainWrapper}>
          <div className={classes.content}>
            <div className={clsx(classes.contentBlock, classes.imageWrapper)}>
              <img src={affiliateProgram} alt="affiliateProgram" />
            </div>
            <div className={clsx(classes.contentBlock)}>
              <h2 className={classes.contentTitle}>
                JOIN OUR AFFILIATE PROGRAM
              </h2>
              <p className="font-italic mb-3">
                As an active Ambassador, you will have the opportunity to join
                our Affiliate program!
              </p>
              <p className={classes.contentDescription}>
                Once you activate your Showcase on Pin the Look, you will be
                invited to become an Affiliate on Pin the Look’s e-commerce
                store,
                <a
                  href="https://mylookshops.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  MyLookShops.com
                </a>
                . As an Affiliate, you can earn a commission when someone
                purchases a
                <a
                  href="https://mylookshops.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  MyLookShops.com{' '}
                </a>
                item after clicking on it from one of your Look Boards.
              </p>
              <p className={classes.contentDescription}>
                Over time, Pin the Look will add more Affiliate Partners
                specializing in unique products and styles. These partners will
                directly invite ambassadors to join their affiliate programs
                based on an Ambassadors’ showcase theme, number of Followers,
                and activity level on Pin the Look.
              </p>
              <h5 className={classes.listTitle}>
                Becoming an Affiliate is a great way to turn your passion for
                decorating into a lucrative side gig!
              </h5>
            </div>
          </div>
          <div className={classes.content}>
            <div
              className={clsx(
                classes.contentBlock,
                classes.affiliateCodeWrapper
              )}
            >
              <h2 className={classes.contentTitle}>
                ADD AFFILIATE CODE TO PIN THE LOOK
              </h2>
              <p className={classes.contentDescription}>
                Once you are set up as an Ambassador, we will send you a
                personalized Affiliate Code for MyLookshops.com products that
                will look like this:{' '}
                <a
                  href="https://lookshops-9536.myshopify.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://lookshops-9536.myshopify.com/
                </a>{' '}
                yourname
              </p>
              <p className={classes.contentDescription}>
                Copy your Affiliate code, then go to your Pin the Look Profile
                and scroll down to the AFFILIATE CODES section. Then, select My
                Look Shops and paste it into the Affiliate Code field.
              </p>
              <div className={classes.affiliateCodeWrapper__img}>
                <img src={affiliatePrograms} alt="affiliatePrograms" />
              </div>
            </div>
            <div className={clsx(classes.contentBlock)}>
              <h2 className={classes.contentTitle}>CURATE THE LOOK</h2>
              <p className={classes.contentDescription}>
                When you curate Look Boards to match the look of an image, you
                can Pin items from anywhere online. If you’d like to include
                commissionable products, you’ll find them in our product
                database in the Curate the Look section.
              </p>
              <div className={classes.affiliateProductsWrapper}>
                <div>
                  <img src={affiliateProducts} alt="affiliateProducts" />
                  <p className={clsx(classes.contentDescription, 'mt-4')}>
                    Products with a Star in a blue circle are Affiliate Products
                    that are Commissionable.
                  </p>
                </div>
                <div>
                  <p className={clsx(classes.contentDescription, 'mb-4')}>
                    To view Commissionable products, select the Affiliate
                    Products database, then select My Look Shops.
                  </p>
                  <img
                    src={affiliateProductsView}
                    alt="affiliateProductsView"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <div
              className={clsx(
                classes.contentBlock,
                classes.stepsTableWrapper,
                'flex-column p-5'
              )}
            >
              <img src={promoteSocials} alt="promoteSocials" />
              <Button
                color="primary"
                size="md"
                onClick={() =>
                  handleLinkOpen(
                    routesByName.dashboard.ambassadorVideos,
                    false,
                    '6'
                  )
                }
                className={classes.watchBtn}
              >
                <SpriteIcon name="play-arrow" />
                Watch the Video
              </Button>
            </div>
            <div className={clsx(classes.contentBlock)}>
              <h2 className={classes.contentTitle}>
                EARN AFFILIATE COMMISSIONS
              </h2>
              <h3 className={classes.contentBoldTitle}>
                Build your brand to earn commissions!
              </h3>
              <div className="d-flex align-items-start mb-4">
                <div className={classes.iconWrapper}>
                  <SpriteIcon name="social-share" size="xl" />
                </div>
                <div className={classes.stepText}>
                  <h4 className={classes.stepToBecomeTitle}>
                    PROMOTE ON SOCIAL MEDIA
                  </h4>
                  <p className={classes.stepToBecomeDesc}>
                    <b>LOOK BOARDS: </b>
                    You can promote your Look Boards on social media. By showing
                    ways to “Get the Look” of beautiful images on your socials,
                    you can drive traffic to your Look Boards with
                    commission-based items and earn cash when purchases are
                    made.
                  </p>
                  <p className={classes.stepToBecomeDesc}>
                    <b>FAVORITE PRODUCTS: </b>
                    You can also promote specific
                    <a
                      href="https://mylookshops.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      MyLookShops.com{' '}
                    </a>
                    products on social media by including your Coupon Link in
                    your posts. You will earn a commission when someone
                    purchases the item using your coupon link.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-4">
                <div className={classes.iconWrapper}>
                  <SpriteIcon name="scene" size="xl" />
                </div>
                <div className={classes.stepText}>
                  <h4 className={classes.stepToBecomeTitle}>CURATE REQUESTS</h4>
                  <p className={classes.stepToBecomeDesc}>
                    As an Ambassador, you can curate matched Requests with
                    Affiliate products to help others get the look of their
                    favorite images. This is a great way to gain followers and
                    earn commissions at the point when purchase intent is high.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmbassadorProgram;
