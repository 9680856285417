import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classes from './GetTheLookYouLove.module.scss';

const Previews = ({ previews }) => {
  const handleClickPreview = useCallback(({ currentTarget }) => {
    const targetId = currentTarget.getAttribute('data-target');
    const target = document.getElementById(targetId);
    window.scrollTo({
      top: target.offsetTop,
      behavior: 'smooth',
    });
  }, []);

  return (
    <ul className={classes.previews}>
      {previews.map(({ title, description, imgUrl, targetId }) => (
        <li key={targetId}>
          <div className={classes.preview}>
            <h3 className={classes.previewTitle}>{title}</h3>
            <div
              onClick={handleClickPreview}
              data-target={targetId}
              className={classes.previewThumbnail}
            >
              <div className={classes.previewBackdrop}>
                <span>How it Works?</span>
              </div>
              <img src={imgUrl} alt={title} />
            </div>
            <div className={classes.previewDescription}>{description}</div>
          </div>
        </li>
      ))}
    </ul>
  );
};

Previews.propTypes = {
  previews: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      imgUrl: PropTypes.string,
      targetId: PropTypes.string,
    })
  ).isRequired,
};

export default Previews;
