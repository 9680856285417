import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import Button from 'components/ui/Button/Button';
import posterImg from 'assets/video/getThePinPreview.png';
import video from 'assets/video/getThePin.mp4';
import classes from './GetThePin.module.scss';

const GetThePin = () => {
  const videoRef = useRef();

  const handlePause = useCallback((e) => e.target.blur(), []);

  const handleExtensionOpen = useCallback(() => {
    window.open(
      'https://chrome.google.com/webstore/detail/pin-the-look-extension/naancmhnjhhidaeommjadnnjhdbdmcgm',
      '_blank'
    );
  }, []);

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Get The Pin</h1>
      <h2 className={classes.subtitle}>Pin Images from Anywhere Online</h2>
      <div className="container">
        <div>
          <p
            className={clsx(
              'text-center',
              'w-100',
              'position-relative',
              classes.sliderTitle
            )}
          >
            <span className="d-flex align-items-center justify-content-center">
              Get the
              <IconButton size="xs" className="mx-1 d-inline-block">
                <SpriteIcon name="pin" size="sm" />
              </IconButton>
              Pin Chrome extension to easily pin your favorite images
            </span>
            <span className="d-flex">
              and products from anywhere online. Simply click on the Add the PIN
              button
            </span>
            <span>
              then select the «Add to Chrome» button to start pinning.
            </span>
            <div
              className={clsx(
                'position-absolute',
                'd-flex',
                'align-items-center',
                classes.titleAfter
              )}
            >
              <SpriteIcon name="right-arrow" size="md" className="mr-2" />
              <Button
                size="sm"
                variant="outlined"
                className={clsx(
                  'd-flex align-items-center px-3',
                  classes.pinBtn
                )}
                onClick={handleExtensionOpen}
              >
                <IconButton size="md" className="mr-1">
                  <SpriteIcon name="pin" size="md" />
                </IconButton>
                <p className={classes.pinBtnText}>Get the Chrome Extension</p>
              </Button>
            </div>
          </p>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={videoRef}
            src={video}
            preload="metadata"
            onPause={handlePause}
            controls
            className={classes.video}
            poster={posterImg}
            autoPlay
          />
        </div>
      </div>
    </div>
  );
};

export default GetThePin;
